<template>
  <div class="pl-[40px] pr-[20px] w-full mobile:w-[calc(100%-32px)] mobile:px-[16px] max-w-[1200px] h-[92px] mobile:h-[52px] fixed bg-white border-[2px] border-tertiary rounded-[10px] flex gap-[20px] mobile:gap-[10px] items-center top-[100px]">
    <img class="w-[24px] h-[24px] mobile:w-[16px] mobile:h-[16px]"
        src="../../assets/images/icon/icon_toast_error.png"/>
    <p class="text-[16px] font-[Suit-Bold] text-tertiary mobile:flex-grow">입력사항 확인 필요</p>
    <p class="text-[16px] text-tertiary flex-grow mobile:hidden">입력사항 확인 필요</p>
    <div class="w-[56px] h-[52px] bg-center bg-no-repeat bg-[length:52px] mobile:w-[16px] mobile:h-[16px]"
         :style="{backgroundImage: `url('${require('@/assets/images/icon/icon-toast-close.png')}')`}"
    ></div>
  </div>
</template>
<script>
export default {
  props: ['text']
}
</script>